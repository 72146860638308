<template>
      <v-row>
        <v-col cols="12" class="text-center">
          <p class="font-weight-regular" v-if="scan">
            Scan data ophalen... een moment geduld.
          </p>
          <p class="font-weight-regular" v-if="analyse">
            Analyse Scan data ophalen... een moment geduld.
          </p>
        </v-col>

      </v-row>
</template>

<script>

export default {
  name: "dashboardOwnScanLoading",
  props: {
    scan: {
      type: Boolean
    },
    analyse: {
      type: Boolean
    }
  },
  data() {
    return {
      rapportAvailable: false,
    }
  },
  methods: {
    informationRequest() {
      this.$router.push({ name: 'informationRequest' })
    }
  }
};
</script>
