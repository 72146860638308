<template>
      <v-row>
        <v-col cols="12" class="text-center">
          <p class="font-weight-regular" v-if="scan">
            Deze scan is niet ingevuld.
          </p>
          <p class="font-weight-regular" v-if="analyse">
            Deze analyse is niet ingevuld.
          </p>
        </v-col>

        <v-col cols="12" class="text-center">
          <p class="font-weight-regular">
            Wil je meer informatie over de verschillende analyses en scans? Of ben je benieuwd hoe Humanwise team en organisatie ontwikkeling
            vormgeeft en faciliteerd? Neem dan gerust contact met ons op.
          </p>
        </v-col>

        <v-col cols="12" class="text-center py-0">
          <v-btn color="primary" block @click="informationRequest">Meer informatie</v-btn>
        </v-col>
      </v-row>
</template>

<script>

export default {
  name: "dashboardOwnScanNotFilled",
  props: {
    scan: {
      type: Boolean
    },
    analyse: {
      type: Boolean
    }
  },
  data() {
    return {
      rapportAvailable: false,
    }
  },
  methods: {
    informationRequest() {
      this.$router.push({ name: 'informationRequest' })
    }
  }
};
</script>
